<template>
    <b-overlay :show="!dataLoaded">
        <div class="w-full" v-if="rooms && rooms.length > 0">
            <div class="bg-tertiary w-100 d-flex flex-wrap flex-column">
                <div class="d-flex flex-lg-nowrap flex-wrap-reverse justify-content-end justify-content-lg-between gap" style="width: 100%; max-width: calc(100% - 1rem); margin-bottom: -5px;">
                    <div class="d-flex hideScrollbar w-100">
                        <div v-for="(item) in rooms" :key="'room_' + item.id" @click="changeRoom(item.id)" class="room" :class="item.id === selectedRoom ? 'selectedRoom' : ''">
                            {{ item.name }}
                        </div>
                    </div>

                    <div class="d-flex items-end">
                        <div class="toggle mr-4">
                            <b-form-checkbox switch size="lg" @change="changeEachRoom" v-model="each_room">
                                <label>{{$t('dashboard.single_room')}}</label>
                            </b-form-checkbox>
                        </div>
                        <div class="toggle">
                            <b-form-checkbox switch size="lg" @change="changeTableView" v-model="table_view">
                                <img alt="Table with white icon." :src="require('@/assets/icons/TableIconWhite.svg')" class="tableIcon"/>
                            </b-form-checkbox>
                        </div>
                    </div>

                </div>

            </div>
            <div style="width: 100%;" id="parentDiv">
                <div v-if="this.rooms && this.rooms.length > 0" class="w-100">
                    <img class="heathmap-arrow" alt="Arrow icon." style="cursor: pointer; position: absolute !important; top: 50%; left: 25px; z-index: 100;" :src="require('@/assets/icons/ArrowIcon.svg')" @click="changeRoomIndex(-1)"/>
                    <img class="heathmap-arrow" alt="Arrow icon." style="cursor: pointer; position: absolute !important; top: 50%; right: 25px; transform: rotate(180deg);  z-index: 100;" :src="require('@/assets/icons/ArrowIcon.svg')" @click="changeRoomIndex(1)"/>
                </div>

                <table-bird-view v-if="table_view && tables" ref="birdView" :tables="tables" />
                <table-list-view v-else-if="!table_view && tables" ref="listView" :tables="tables"/>
            </div>

        </div>
        <div v-else>
            {{ $t('graphs.no_data') }}
        </div>
    </b-overlay>
</template>

<script>
    import 'vue-select/dist/vue-select.css'
    import TableBirdView from './components/TableBirdView.vue'
    import {BFormCheckbox, BOverlay} from 'bootstrap-vue'
    import TableListView from './components/TableListView.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BOverlay,
            TableBirdView,
            BFormCheckbox,
            TableListView },
        data() {
            return {
                dataLoaded: false,
                table_view: true,
                selectedRoom: null,
                rooms: [],
                tables: [],
                tabIndex: 0,
                each_room: false
            }
        },
        props: {
            startDate: {
                type: Date
            },
            endDate: {
                type: Date
            }
        },
        methods:{
            changeEachRoom() {
                this.loadData(true)
            },
            changeRoomIndex(direction) {
                const currentRoomIndex = this.rooms.findIndex(ele => ele.id === this.selectedRoom)


                if (currentRoomIndex === -1) {
                    return
                }

                let newIndex = currentRoomIndex

                if (currentRoomIndex + direction < 0) {
                    newIndex = this.rooms.length - 1
                } else if (currentRoomIndex + direction >= this.rooms.length) {
                    newIndex = 0
                } else {
                    newIndex += direction
                }

                const newRoomId = this.rooms[newIndex].id
              
                if (!newRoomId) {
                    return
                }

                this.tabIndex = newIndex
                this.changeRoom(newRoomId, true)
            },
            changeTableView() {
                //localStorage.setItem('tablesView', this.table_view)
                if (this.table_view) {
                    if (this.$refs.birdView) {
                        this.$refs.birdView.drawAll()
                    }
                }
            },
            changeRoom(id, scroll) {
                this.selectedRoom = id
                const room = this.rooms.find(ele => ele.id === id)
                this.tables = room.tables

                if (this.table_view) {
                    this.$nextTick(() => {
                        if (this.$refs.birdView) {
                            this.$refs.birdView.drawAll()
                        }
                    })
                }

                if (scroll) {
                    this.$nextTick(() => {
                        const selectedRoom = document.getElementsByClassName('selectedRoom')
                        if (selectedRoom.length > 0) {
                            selectedRoom[0].scrollIntoView({ behavior: 'smooth', inline: 'center' })
                        }
                    })
                }
            },
            async loadData(reload) {
                this.dataLoaded = false

                try {
                    const data = {
                        'start_date': this.startDate.getTime(),
                        'end_date': this.endDate.getTime()
                    }
                    const response = await this.$http.post(`/api/dashboard/v1/dashboard/rooms/${this.each_room}`, data)
                    this.rooms = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }

                if (!reload) {
                    if (this.rooms.length > 0) {
                        this.selectedRoom = this.rooms[this.tabIndex].id
                    }
                }

                this.changeRoom(this.selectedRoom, false)
                this.dataLoaded = true
            }
        },

        async mounted() {
            this.tabIndex = 0
            this.table_view = true
        },

        watch: {
            async startDate() {
                await this.loadData()
            },
            async endDate() {
                await this.loadData()
            }
        },

        computed: {
            tab() {
                return this.tabIndex
            }
        }

    }
</script>

<style lang="scss" scoped>
    @import '~@core/scss/base/bootstrap-extended/include';


    @media (max-width: (map-get($grid-breakpoints, sm))) {
        .heathmap-arrow {
            width: 25px!important;
            height: 25px!important;
        }
    }

    .heathmap-arrow {
        width: 50px;
        height: 50px;
    }


    //@import "@/assets/scss/overrides/base/colors/variables-dark.scss";

    .outerHeight{
        height: 85%;
    }

    #parentDiv {
        height:100%;
    }

    .toggle{
        //max-width: 360px;
        width: 100%;
    }

    .line-top{
        border-top: 2px solid gray; //$theme-dark-border-color;
    }

    .testMiddle{
        flex-grow: 1;
        overflow: auto;
    }

    .testFooter{
        position: relative;
        transition: height 250ms ease-in-out;
    }

    .tableIcon {
        filter: invert(0.7);
    }

    .dark-layout .tableIcon {
        filter: none;
    }

    .dark-layout .selectedRoom {
        background: #292929;
        border-radius: 12px 12px 0 0;
    }

    .selectedRoom {
        background: #e7e7e7;
        border-radius: 12px 12px 0 0;
    }

    .room {
        padding: 12px;
        padding-bottom: 14px;
        margin-bottom: -5px;
        cursor: pointer;
        font-weight: bold;
        white-space: nowrap;
    }

    .overflow-y-hidden{
        overflow-y: hidden;
    }

    .hideScrollbar{
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
    }
    .hideScrollbar::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    .gap{
        gap: 0 2rem;
    }
</style>
<style>
    .toggle .custom-switch .custom-control-label {
        padding-left: 0!important;
    }
</style>